<template>
  <el-dialog
    :modal="false"
    title=""
    :visible.sync="showDialog"
    fullscreen
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="" prop="type">
          <el-select
            v-model="form.type"
            clearable
            style="width: 140px"
            placeholder="类型"
          >
            <el-option
              v-for="(i, idx) in typeList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="user_id">
          <el-select
            v-model="form.user_id"
            clearable
            style="width: 140px"
            placeholder="拍照人"
          >
            <el-option
              v-for="(i, idx) in userList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button>导出</el-button>
        </el-form-item>
      </el-form>
      <el-form
        ref="form2"
        :model="info"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="客户名称:" prop="cust_name">
          <el-input v-model="info.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址:" prop="address">
          <el-input v-model="info.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input v-model="bossMo" disabled></el-input>
        </el-form-item>
      </el-form>
      <div class="pic-wrapper">
        <div v-for="(item, index) in picData" :key="index" class="pic-item">
          <el-image
            style="width: 180px; height: 180px"
            :src="item.photo"
            :preview-src-list="bigPicList"
          ></el-image>
          <div class="type">{{ item.type_name }}</div>
          <div class="name">{{ item.user_name }}</div>
          <div class="time">{{ item.create_at }}</div>
        </div>
      </div>
    </div>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </el-dialog>
</template>
<script>
  import { getAddress, getAllPic, picTypeList } from '@/api/performance'
  import { getStaffList } from '@/api/setPrice'

  export default {
    name: 'CheckAllPic',
    components: {},
    data() {
      return {
        showDialog: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        loading: false,
        typeList: [],
        userList: [],
        time: [],
        form: {
          user_id: '', //员工ID
          cust_id: '', //店铺ID
          type: '', //照片类型 门头\货架
          start_time: '', //开始时间 如2020-12-22
          end_time: '', //结束时间 如2020-12-22
          pageNo: 1,
          pageSize: 10,
        },
        info: {
          address: '',
          boss: '',
          cust_name: '',
          mobile: '',
        },
        picData: [],
        bigPicList: [],
      }
    },
    computed: {
      bossMo() {
        return this.info.boss + ' ' + this.info.mobile
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showDialog(val) {
        if (val) {
          this.initSelect()
          this.fetchData()
        } else {
          this.resetForm()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await getAllPic(this.form)
        console.log(data)
        this.info = data.rows.cust_info
        this.picData = data.rows.data
        this.picData.forEach((item) => {
          this.bigPicList.push(item.photo)
        })
        this.total = Number(data.total)
        this.loading = false
      },
      initSelect() {
        // 拍照类型下拉
        picTypeList().then((res) => {
          this.typeList = res.data
        })
        // 人员下拉
        getStaffList().then((res) => {
          if (res.code == 200) this.userList = res.data
        })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.$refs.form2.resetFields()
        this.time = []
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pic-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .pic-item {
      margin: 0 10px 10px 0;
      font-size: 14px;
      text-align: center;
      .name {
        line-height: 22px;
      }
    }
  }
</style>
