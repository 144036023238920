<template>
  <!-- NAME[epic=绩效] 拜访拍照 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          placeholder="请选择拍照人"
          style="width: 160px"
        >
          <el-option
            v-for="item in staffList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-val-alldata="selectCustId"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="位置状态"
        >
          <el-option
            v-for="(i, idx) in locationList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label='' prop=''>
        <el-select v-model='form.' clearable style='width: 140px;' placeholder='请选择'>
         <el-option
           v-for='(i, idx) in '
           :key='idx'
           :value='i.'
           :label='i.'
         ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.type_id1"
          clearable
          style="width: 140px"
          placeholder="拍照类型"
        >
          <el-option
            v-for="(i, idx) in zhaopianSelect"
            :key="idx"
            :value="i.id"
            :label="i.type_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.type_id2"
          clearable
          style="width: 140px"
          placeholder="陈列类型"
        >
          <el-option
            v-for="(i, idx) in leixingSelect"
            :key="idx"
            :value="i.id"
            :label="i.type_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.action_type"
          clearable
          style="width: 140px"
          placeholder="动作"
        >
          <el-option
            v-for="(i, idx) in dongzuoSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="success" @click="handleTypeSet">类型设置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExportPic">导出照片</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <!-- <el-button @click="handleImport2">导入商单2.0拜访记录</el-button> -->
        <el-upload
          class="upload-demo"
          :show-file-list="false"
          :data="importImgData"
          name="files"
          :headers="headers"
          :action="baseURL + '/baseAdmin/common/one-upload-as-save'"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="warning">导入商单2.0拜访记录</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '照片'">
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="(item, index) in row.imgs" :key="index">
                <el-image
                  style="width: 80px; height: 80px; margin-right: 10px"
                  :src="item.img"
                  :preview-src-list="row.imgsArray"
                >
                  <div slot="error" class="image-slot2">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div style="text-align: center">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div v-else-if="item.label == '位置'">
            <span v-if="row.abnormal == 0">正常</span>
            <span
              v-else
              style="color: red; text-decoration: underline; cursor: pointer"
              @click="handleShowErr(row)"
            >
              异常
            </span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作" width="140px">
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handelCheckPic($index, row)"
          >
            查看客户全部照片
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <show-err ref="showErr"></show-err>
    <check-all-pic ref="checkAllPic"></check-all-pic>
    <type-set ref="typeSet" @refresh="fetchData"></type-set>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { baseURL } from '@/config'

  import { pictureList } from '@/api/performance'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getStaffList } from '@/api/setPrice'
  import ShowErr from './components/showErr.vue'
  import CheckAllPic from './components/checkAllPic.vue'
  import TypeSet from './components/typeSet'
  import { downloadFile, postAction } from '@/api/Employee'
  import dateFormat from '@/utils/Timeformat'
  export default {
    name: 'VisitPictures',
    components: { ClientSearch, ShowErr, CheckAllPic, TypeSet },
    data() {
      return {
        loading: false,
        list: [],
        total: 0,
        time: [],
        staffList: [],
        importImgData: {
          type: 10,
        },
        locationList: [
          // 态 1正常 2异常
          {
            id: 1,
            name: '正常',
          },
          {
            id: 2,
            name: '异常	',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          cust_id: '',
          user_id: '', //员工ID
          cust_keyword: '', //店铺名称\简拼
          start_time: '', //开始时间 如2020-12-22
          end_time: '', //结束时间 如2020-12-22
          status: '', //位置状态 1正常 2异常
          pageNo: 1, //
          pageSize: 10, //
          // 类型是type_id,动作是action_type
          type_id1: '',
          type_id2: '',
          action_type: '',
        },
        zhaopianSelect: [],
        dongzuoSelect: [],
        leixingSelect: [],
        headers: { token: '' },
        checkList: [
          '拍照人',
          '客户',
          '位置',
          '拜访动作',
          '拍摄时间',
          '照片',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '拍照人',
            prop: 'user_name',
            width: '110',
          },
          {
            order: 2,
            label: '客户',
            prop: 'cust_name',
            width: '230',
          },
          {
            order: 3,
            label: '位置',
            prop: 'abnormal',
            width: '110',
          },
          {
            order: 4,
            label: '拜访动作',
            prop: 'action_type',
            width: '100',
          },
          {
            order: 5,
            label: '拍摄时间',
            prop: 'create_at',
            width: '190',
          },
          {
            order: 6,
            label: '照片',
            prop: 'pic',
            width: '475',
          },
          {
            order: 7,
            label: '备注',
            prop: 'remark',
            width: '',
          },
        ],
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      // taskAdmin/visit/action-type  动作下拉
      postAction('/taskAdmin/visit/action-type').then((r) => {
        this.dongzuoSelect = r.data
      })
      // 类型下拉
      postAction('baseAdmin/common/dealer-photo-type-option', { type: 2 }).then(
        (r) => {
          this.leixingSelect = r.data
        }
      )
      // 拜访和陈列类型下拉接口baseAdmin/common/dealer-photo-type-option  type=1拍照2陈列
      postAction('baseAdmin/common/dealer-photo-type-option', { type: 1 }).then(
        (r) => {
          this.zhaopianSelect = r.data
        }
      )
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, totalCount } = await pictureList(this.form)
        this.list = data.rows
        this.list = this.list.map((item) => {
          item.imgsArray = item.imgs.map((lists) => {
            return lists.img
          })
          return item
        })
        console.info(this.list)
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 类型设置
      handleTypeSet() {
        this.$refs.typeSet.showDialog = true
      },
      // 表格异常点击
      handleShowErr(row) {
        console.log(row)
        this.$refs.showErr.address = {
          latitude: row.latitude,
          longitude: row.latitude,
          latitude2: row.latitude_info,
          longitude2: row.longitude_info,
        }
        this.$refs.showErr.showDialog = true
      },
      selectCustId(val) {
        console.log('vvv', val)
        this.form.cust_id = val.id
      },
      handelCheckPic(index, row) {
        console.log(row)
        this.$refs.checkAllPic.form = {
          // user_id: row.user_id, //员工ID
          cust_id: row.cust_id, //店铺ID
          user_id: '',
          type: '',
          // type: row.type, //照片类型 门头\货架
          start_time: dateFormat(new Date()), //开始时间 如2020-12-22
          end_time: dateFormat(new Date() - 1000 * 60 * 60 * 24 * 30), //结束时间 如2020-12-22
          pageNo: 1,
          pageSize: 10,
        }
        this.$refs.checkAllPic.time = [
          dateFormat(new Date() - 1000 * 60 * 60 * 24 * 30),
          dateFormat(new Date()),
        ]
        // this.$refs.checkAllPic.info = {
        //   cust_id: row.cust_id,
        //   user: row.user_name + ' ' + row.mobile,
        // }
        this.$refs.checkAllPic.showDialog = true
      },
      handleExport() {
        // downloadFile('/taskAdmin/visit/list-export', '拜访拍照.xlsx', this.form)
        postAction('/baseAdmin/export/add-export', {
          type: 4,
          ex_data: JSON.stringify(this.form),
        }).then((r) => {
          this.$message.success('导出中,请查看导出消息列表')
        })
      },
      handleExportPic() {
        postAction('/baseAdmin/export/add-export', {
          type: 3,
          ex_data: JSON.stringify(this.form),
        }).then((r) => {
          this.$message.success('导出中,请查看导出消息列表')
        })
        // downloadFile(
        //   '/taskAdmin/visit/list-zip-export',
        //   '拜访拍照.zip',
        //   this.form
        // )
      },
      handleSuccess(res, file, fileList) {
        console.log('res')
        if (res.code == 200) {
          this.$message.success('上传成功')
        } else {
          this.$message.error('上传失败')
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
