<template>
  <el-dialog
    :modal="false"
    title=""
    :visible.sync="showDialog"
    width="450px"
    top="20vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="门店位置:" prop="">
          {{ form.cust_addr }}
        </el-form-item>
        <el-form-item label="操作位置:" prop="">
          {{ form.photo_addr }}
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getAddress } from '@/api/performance'
  export default {
    name: 'ShowErr',
    components: {},
    data() {
      return {
        showDialog: false,
        address: {
          latitude: '', //拜访时纬度
          longitude: '', //拜访时经度
          latitude2: '', //店铺纬度
          longitude2: '', //店铺经度
        },
        form: {
          photo_addr: '',
          cust_addr: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        let { data } = await getAddress(this.address)
        this.form = data
      },
      clearForm() {
        this.form = { photo_addr: '', cust_addr: '' }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
