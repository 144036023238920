var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "拍照类型设置",
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "div",
            { staticClass: "top", staticStyle: { "text-align": "center" } },
            [
              _c("el-input", {
                staticStyle: {
                  width: "240px",
                  "margin-right": "10px",
                  "margin-bottom": "10px",
                },
                attrs: { placeholder: "输入类型名称" },
                model: {
                  value: _vm.typeName,
                  callback: function ($$v) {
                    _vm.typeName = $$v
                  },
                  expression: "typeName",
                },
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: "排序",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: { type: "number" },
                          on: { change: _vm.sort },
                          model: {
                            value: row.sort,
                            callback: function ($$v) {
                              _vm.$set(row, "sort", $$v)
                            },
                            expression: "row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "类型名称",
                  width: "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          model: {
                            value: row.name,
                            callback: function ($$v) {
                              _vm.$set(row, "name", $$v)
                            },
                            expression: "row.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  width: "100",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDeleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
            [_vm._v("修改")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }