var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "",
        visible: _vm.showDialog,
        fullscreen: "",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "类型" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "拍照人" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.userList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [_c("el-button", [_vm._v("导出")])],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form2",
              attrs: {
                model: _vm.info,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称:", prop: "cust_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.info.cust_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "cust_name", $$v)
                      },
                      expression: "info.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.info.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "address", $$v)
                      },
                      expression: "info.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.bossMo,
                      callback: function ($$v) {
                        _vm.bossMo = $$v
                      },
                      expression: "bossMo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pic-wrapper" },
            _vm._l(_vm.picData, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "pic-item" },
                [
                  _c("el-image", {
                    staticStyle: { width: "180px", height: "180px" },
                    attrs: {
                      src: item.photo,
                      "preview-src-list": _vm.bigPicList,
                    },
                  }),
                  _c("div", { staticClass: "type" }, [
                    _vm._v(_vm._s(item.type_name)),
                  ]),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.user_name)),
                  ]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(item.create_at)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }