var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "user_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "请选择拍照人" },
                  model: {
                    value: _vm.form.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_id", $$v)
                    },
                    expression: "form.user_id",
                  },
                },
                _vm._l(_vm.staffList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "select-idx selectDC" },
                on: { "select-val-alldata": _vm.selectCustId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "位置状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.locationList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "拍照类型" },
                  model: {
                    value: _vm.form.type_id1,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type_id1", $$v)
                    },
                    expression: "form.type_id1",
                  },
                },
                _vm._l(_vm.zhaopianSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.type_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "陈列类型" },
                  model: {
                    value: _vm.form.type_id2,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type_id2", $$v)
                    },
                    expression: "form.type_id2",
                  },
                },
                _vm._l(_vm.leixingSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.type_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "动作" },
                  model: {
                    value: _vm.form.action_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "action_type", $$v)
                    },
                    expression: "form.action_type",
                  },
                },
                _vm._l(_vm.dongzuoSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.handleTypeSet },
                },
                [_vm._v("类型设置")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExportPic } }, [
                _vm._v("导出照片"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    "show-file-list": false,
                    data: _vm.importImgData,
                    name: "files",
                    headers: _vm.headers,
                    action:
                      _vm.baseURL + "/baseAdmin/common/one-upload-as-save",
                    "on-success": _vm.handleSuccess,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "warning" } },
                    [_vm._v("导入商单2.0拜访记录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "照片"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                  },
                                },
                                _vm._l(row.imgs, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticStyle: {
                                            width: "80px",
                                            height: "80px",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            src: item.img,
                                            "preview-src-list": row.imgsArray,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot2",
                                              attrs: { slot: "error" },
                                              slot: "error",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-picture-outline",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ])
                          : item.label == "位置"
                          ? _c("div", [
                              row.abnormal == 0
                                ? _c("span", [_vm._v("正常")])
                                : _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "text-decoration": "underline",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowErr(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 异常 ")]
                                  ),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: { prop: "", align: "center", label: "操作", width: "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handelCheckPic($index, row)
                          },
                        },
                      },
                      [_vm._v(" 查看客户全部照片 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("show-err", { ref: "showErr" }),
      _c("check-all-pic", { ref: "checkAllPic" }),
      _c("type-set", { ref: "typeSet", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }