<template>
  <el-dialog
    :modal="false"
    title="拍照类型设置"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    center=""
  >
    <div class="orderTest-container">
      <div class="top" style="text-align: center">
        <el-input
          v-model="typeName"
          placeholder="输入类型名称"
          style="width: 240px; margin-right: 10px; margin-bottom: 10px"
        ></el-input>
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </div>
      <el-table stripe :data="list">
        <el-table-column prop="sort" label="排序" width="100" align="center">
          <template #default="{ row }">
            <el-input
              v-model="row.sort"
              type="number"
              @change="sort"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类型名称" width="" align="center">
          <template #default="{ row }">
            <el-input v-model="row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" width="100" label="操作">
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleEdit">修改</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import {
    picTypeList,
    picTypeAdd,
    picTypeDelete,
    picTypeEdit,
  } from '@/api/performance'
  export default {
    name: 'TypeSet',
    components: {},
    data() {
      return {
        showDialog: false,
        typeName: '',
        list: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.typeName = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        let { data } = await picTypeList()
        console.log(data)
        this.list = data
      },
      handleDeleteRow(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            picTypeDelete({
              id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$emit('refresh')

                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleAdd() {
        picTypeAdd({ name: this.typeName }).then((res) => {
          this.fetchData()
          this.$emit('refresh')

          this.$message.success('添加成功')
          this.typeName = ''
        })
      },

      handleEdit() {
        let data = JSON.stringify(this.list)
        picTypeEdit({ type_data: data }).then((res) => {
          this.$message.success('修改成功')
          this.typeName = ''
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
      // 排序
      sort() {
        this.list = _.sortBy(this.list, (item) => Number(item.sort))
      },
    },
  }
</script>
<style lang="scss" scoped></style>
